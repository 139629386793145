import { MDXProvider } from "@mdx-js/react"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import * as React from "react"
import { Helmet } from "react-helmet"
import CodeBlock from "../../components/code-block"

const Scripture = ({ title, link, children }) => {
  return (
    <div className="border-solid border bg-zinc-100 bg-opacity-50 p-4 -mx-4 my-4 font-serif rounded-md">
      <div className="font-bold text-amber-600 mb-1">
        <a
          className="no-underline text-amber-600"
          href={link && link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {title}
        </a>
      </div>
      {children}
    </div>
  )
}

const DiscussionQuestion = ({ children }) => {
  return (
    <div className="border-solid border border-amber-300 bg-amber-50 bg-opacity-50 p-6 -mx-4 my-4 font-serif rounded-md text-center">
      <div className="font-bold text-sm text-amber-600 opacity-40 tracking-widest uppercase">
        Discussion Question
      </div>
      <div className="text-amber-900 font-medium leading-6">{children}</div>
    </div>
  )
}

const ImageForDownload = ({ title, src, children }) => {
  return (
    <div className="-mx-4 mt-4 font-serif rounded-md text-center">
      <div className="text-amber-900 font-medium leading-6">
        <img src={src} alt={title} className="mb-2" />
        {children}
      </div>
      <div>
        <p className="text-xs text-gray-600 opacity-40 tracking-widest uppercase">
          {title}
        </p>
      </div>
      <a
        href={src}
        download={title}
        className="font-bold text-sm text-amber-600 opacity-40 tracking-widest uppercase no-underline"
      >
        Download this Image
      </a>
    </div>
  )
}

const SpotifyEmbed = ({ uri }) => {
  // if the uri starts with https: (like https://open.spotify.com/track/690W3U6pfNcfy7JN8GtqUi?si=c1690062aa7a41c6) cut off the https://open.spotify.com/track/ portion and the ?si=... portion
  const newUri = uri
    .replace(/https:\/\/open.spotify.com\/track\//, ``)
    .replace(/\?si=.*/, ``)
  return (
    <div className="flex justify-center">
      <iframe
        src={`https://open.spotify.com/embed/track/${newUri}`}
        width="100%"
        height="80"
        frameBorder="0"
        allowtransparency="true"
        allow="encrypted-media"
        title="Spotify Embed"
      />
    </div>
  )
}

const components = {
  pre: CodeBlock,
  Scripture,
  DiscussionQuestion,
  ImageForDownload,
  SpotifyEmbed,
}

export default function Blog(props) {
  const { data } = props
  const ogTitle = data.mdx.frontmatter.title
  const ogImageUrl = ``

  return (
    <main>
      <Helmet>
        <title>{ogTitle}</title>
        <meta name="description" content={data.mdx?.excerpt} />
        <meta name="twitter:title" content={ogTitle} />
        <meta property="og:title" content={ogTitle} />
        <meta name="image" content={ogImageUrl} />
        <meta itemProp="image" content={ogImageUrl} />
        <meta name="twitter:image" content={ogImageUrl} />
        <meta property="og:image" content={ogImageUrl} />
      </Helmet>
      <section className="divide-y divide-zinc-200">
        <div className="pt-6 px-6 pb-8 space-y-2 md:space-y-5 text-center">
          <h1>{data.mdx.frontmatter.title}</h1>
          <StaticImage
            alt="Kyle Gill portrait"
            width={36}
            layout="FIXED"
            placeholder="TRACED_SVG"
            src="../../images/portrait-square.jpg"
            className="rounded-full ring-2 ring-offset-2 ring-amber-500 mx-auto"
          />
          <div>
            <p className="text-md leading-6 text-zinc-500 mb-2">
              Kyle Gill, Software Engineer, Particl
            </p>
            <dt className="text-xs font-semibold tracking-wide text-zinc-400 uppercase">
              Last updated
            </dt>
            <dd className="mb-2">
              <time
                dateTime={data.mdx.frontmatter.dateString}
                className="text-sm text-zinc-600"
              >
                {data.mdx.frontmatter.date}
              </time>
            </dd>
            {/* <p className="flex justify-center items-center text-md leading-6 text-zinc-500 mb-2">
              <HiOutlineEye size={24} className="mr-1" />
              {typeof views === `string` ? (
                <div className="animate-pulse w-6 h-4 rounded bg-zinc-100" />
              ) : (
                HRNumbers.toHumanString(views)
              )}
            </p> */}
          </div>
        </div>
      </section>
      <section className="container border-t border-zinc-200">
        <div className="flex flex-col gap-7 py-7">
          <div className="prose w-full mx-auto mb-4">
            <MDXProvider components={components}>
              <MDXRenderer>{data.mdx.body}</MDXRenderer>
            </MDXProvider>
          </div>
          <div className="flex flex-col border-t border-zinc-200 pt-8">
            <Link className="text-amber-500 hover:text-amber-600" to="/essays">
              ← Back to all essays
            </Link>
          </div>
        </div>
      </section>
    </main>
  )
}

export const pageQuery = graphql`
  query MdxQuery($slug: String!) {
    mdx(slug: { eq: $slug }) {
      id
      excerpt
      body
      frontmatter {
        title
        date(formatString: "dddd, MMM D, yyyy")
        dateString: date
      }
    }
  }
`
